import React from "react";

const About = () => {
    return(
        <div className="about">
            <div className="about-content">
                <h2>About</h2>
            </div>
        </div>
    )
}

export default About;