import React from "react";

const Resume = () => {
    return(
        <div className="resume">
            <div className="resume-content">
                <h2>Resume</h2>
            </div>
        </div>
    )
}

export default Resume;