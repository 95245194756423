import React from "react";

const SelectedProject = () => {

    const style = {
        wrapper: {
            paddingTop:100,
        }
    }


    return(
        <div style={style.wrapper}>
            SelectedProject
        </div>
    );
};

export default SelectedProject;